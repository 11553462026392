<template>
    <app-base>
        <header class="border-b">
            <nuxt-link
                class="text-button flex h-10 items-center justify-center bg-primary-500 py-2 text-lg font-medium uppercase hover:underline"
                :to="organization.topBarLink"
            >
                {{ organization.topBarText }}
            </nuxt-link>

            <div
                class="relative flex flex-col justify-center overflow-hidden bg-white"
            >
                <div>
                    <div
                        class="relative mx-0 flex items-center justify-center gap-4 px-4 py-6 md:px-7 lg:static lg:px-16 lg:py-6"
                    >
                        <div class="flex items-center gap-2">
                            <nuxt-link :to="orgHomeLink()">
                                <img
                                    :src="organization.logo"
                                    :alt="organization.longName"
                                    class="max-h-12"
                                />
                            </nuxt-link>
                        </div>

                        <div
                            class="absolute bottom-0 right-4 top-0 flex items-center md:right-7"
                        >
                            <contact-us-button
                                v-if="
                                    organization.contactUsLinkText !== null &&
                                    viewer !== undefined
                                "
                                :link-text="organization.contactUsLinkText"
                                :facility-id="viewer?.homeFacility?.id"
                                org-level
                            />
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="flex flex-1 flex-col">
            <div
                class="z-2 mx-0 my-2 flex-1 px-4 py-4 md:mx-6 md:my-6 lg:mx-8 lg:px-8"
            >
                <slot />
            </div>

            <powered-by-footer />
        </div>
    </app-base>
</template>

<script setup lang="ts">
import NuxtLink from '#app/components/nuxt-link'
import PoweredByFooter from '~/components/PoweredByFooter.vue'
import ContactUsButton from '~/components/Layouts/ContactUsButton.vue'
import AppBase from '~/components/Layouts/AppBase.vue'
import { computed, onBeforeUnmount, ref } from 'vue'
import { useRpQuery } from '~/composables/graphql'
import { useQuery } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import { provideViewer, useViewer } from '~/composables/use-login'
import {
    type ActiveFacility,
    provideActiveFacility
} from '~/composables/use-active-facility'
import { useRouteLink } from '~/composables/routing'
import { useHead } from 'unhead'

const { orgHomeLink } = useRouteLink()
const query = useRpQuery({ orgLevel: true })
// TODO: when there are more than 100 facilities
// TODO: Limit to active facilities
const { data, suspense } = useQuery({
    queryKey: ['LandingPageQuery'],
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query LandingPageQuery {
                    organization {
                        code
                        shortName
                        longName
                        logo
                        topBarLink
                        topBarText
                        contactUsLinkText
                        branding {
                            styleString
                        }
                    }
                    facilities(first: 100) {
                        edges {
                            node {
                                id
                                shortName
                                longName
                                slug
                                active
                                storefront {
                                    id
                                }
                            }
                        }
                    }
                }
            `)
        )
})

const organization = computed(() => data.value?.organization)

await Promise.all([suspense(), provideViewer()])

const viewer = useViewer()
if (viewer.value !== undefined) {
    const homeFacility = ref<ActiveFacility>(viewer.value.homeFacility)
    provideActiveFacility(homeFacility)
}

const unmount = ref(false)
const headStyle = computed(() => {
    if (unmount.value) {
        return []
    }

    return organization.value?.branding.styleString
})

useHead({
    style: headStyle
})

onBeforeUnmount(() => {
    // Clear styles when leaving the component
    unmount.value = true
})
</script>
